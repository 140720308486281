<template>
    <Dialog v-model:visible="loading" :style="{ width: '250px' }" header="Please wait.." :modal="true" class="p-fluid">
        <div class="grid card" style="align: center">
            <i class="pi pi-spin pi-spinner" style="fontsize: 20rem"></i>
        </div>
    </Dialog>
    <Dialog v-model:visible="showTutorial" :style="{ width: '80%' }" header="Formulator Tutorial" :modal="true" class="p-fluid">
        <div class="card">
            <h2>Placeholder for tutorial content</h2>
        </div>
    </Dialog>

    <Dialog v-model:visible="showModelInfo" :style="{ width: '90%' }" :header="'About ' + this.SharedState.state.modelName + ' and TiPure Formulator'" :modal="true" class="p-fluid">
        <modelinfo :modelname="modelName" :detaildescription="DetailedDescription" :modeldescription="modelDescription" />
        <!-- <modelinfo /> -->
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-rounded" @click="showModelInfo = false" />
        </template>
    </Dialog>
    <Dialog v-model:visible="costDialog" :style="{ width: '650px' }" header="Cost Settings (Currency/tonne)" :modal="true" class="p-fluid">
        <div class="grid card cost-grid">
            <div class="col-12">
                <span class="col-5"> Unit of measurement for cost </span>
                <InputText class="col-2" v-model="CostUnit" />
            </div>
            <template v-for="attr in modelInputAttributes" :key="attr">
                <span class="col-3"> {{ attr.AlternateName }}</span>
                <InputText class="col-3" v-model="attr.CostPerUnit" @change="ComputeCost" />
            </template>
        </div>
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-rounded p-button-raised" @click="costDialog = false" />
            <Button label="Save" icon="pi pi-save" class="p-button-rounded p-button-raised" @click="UpdateSettings" />
        </template>
    </Dialog>

    <Dialog v-model:visible="propertiesFilter" :style="{ width: '650px' }" header="Filter Properties" :modal="true" class="p-fluid">
        <div class="grid card">
            <div class="col-3">Selected Columns</div>
            <div class="col-9">
                {{ filtered }}
            </div>
            <template v-for="attr in modelOutputAttributes" :key="attr">
                <div class="col-3">
                    <label :for="attr.Name + 'filter'"> {{ attr.AlternateName == null || attr.AlternateName == '' ? attr.Name : attr.AlternateName }}</label>

                    <Checkbox :id="attr.Name + 'filter'" name="filtered" :value="attr.Name" v-model="filtered" />
                </div>
            </template>
        </div>
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-rounded p-button-raised" @click="propertiesFilter = false" />
            <Button
                label="Save"
                icon="pi pi-save"
                class="p-button-rounded p-button-raised"
                @click="
                    DispMainGraphdata(formulations,filtered)
                    filteredProperties = filtered;
                    propertiesFilter = false;
                    if(showGraph)propertiesGraph();
                "
            />
        </template>
    </Dialog>
    <Dialog v-model:visible="editPredictionSettings" :style="{ width: '650px' }" header="Prediction Settings" :modal="true" class="p-fluid">
        <div class="grid card">
            <div class="col-6" v-for="(attr, index) in modelProperties" :key="attr">
                <span class="col-3"> {{ attr.Name }}</span>
                <span class="col-3"> {{ index }}</span>
            </div>
        </div>
        <template #footer>
            <Button label="Close" icon="pi pi-times" class="p-button-rounded p-button-raised" @click="costDialog = false" />
            <Button label="Save" icon="pi pi-save" class="p-button-rounded p-button-raised" @click="UpdateSettings" />
        </template>
    </Dialog>
    <div class="formulator">
        <Toast />
        <div class="grid card shadow-2" v-show="modelInputAttributes != null">
            <div v-helptext.right="modelDescription" class="col-4 title header-row">
                <span>{{ this.modelName }} Model </span>
                <Button label="Info" icon="pi pi-info-circle" @click="showModelInfo = true" class="p-button-rounded p-button-raised p-button-info" />
            </div>
            <div class="col-2 title header-row"></div>
            <div class="col-6 header-row">
                <a class="p-button-rounded p-button-raised p-button-info" href="https://ccuse2tipurestorage.blob.core.windows.net/public/formulatortutorial.pdf" target="_new">
                    <Button label="Help" icon="pi pi-question-circle" class="p-button-rounded p-button-raised p-button-info" />
                </a>
                <Button label="Cost Settings" icon="pi pi-cog" @click="costDialog = true" class="p-button-rounded p-button-raised p-button-secondary" />
                <Button label="Predict" icon="pi pi-book" @click="predictProperties" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" />
            </div>

            <div class="col-1 heading top-row"></div>
            <div class="col-10 heading top-row">
                <i class="pi pi-caret-left" v-show="ingredientStartIndex > 0" @click="ingredientStartIndex--"></i>
                Ingredients
                <i class="pi pi-caret-right" v-show="ingredientStartIndex < selectedAttribute.length - 10" @click="ingredientStartIndex++"></i>
            </div>
            <div class="col-1 heading"></div>
            <!-- <div class="col-1 model"></div> -->

            <div class="col-1 heading">Formula</div>

            <div class="col-1 subheading" v-for="n in 10" :key="n">
                <div v-helptext.top="getIngredientProperty(n - 1 + ingredientStartIndex, 'Description')">
                    <label> {{ getAtributeAlternateName(n - 1 + ingredientStartIndex) }} </label>
                    <!--getIngredientProperty(n - 1 + ingredientStartIndex, 'Description')-->
                    <label>
                        <small> ({{ getAtributeRange(n - 1 + ingredientStartIndex) }} )</small>
                    </label>
                </div>
            </div>
            <div class="col-1 heading">Total</div>
            <div v-for="(formulation, index) in formulations" :key="index" class="row col-12 grid" :class="{ formulationError: formulation['Total'] != 100, selected: formulation['selected'] }">
                <div class="col-1 row-head clickable" @click="formulation['selected'] = !formulation['selected']">
                    <label v-show="!editMode"> {{ formulation.Name }} </label>
                    <InputText v-show="editMode" :placeholder="formulation.Name" v-bind:class="{ outOfRange: Checkexists(index,formulations[index]['Name'])}" v-model="formulations[index]['Name']" />
                    <!-- <InputText v-show="editMode" :value="formulation.Name" :placeholder="formulation.Name" v-model="testtmp[index][formulation.Name]" v-bind:class="{ outOfRange: Checkexists(testtmp[index][formulation.Name])}" /> -->

                </div>
                <div class="col-1 input" v-for="n in 10" :key="n" v-bind:class="{ oddRow: index % 2 == 0 }">
                    <!-- <label v-show="!editMode" v-bind:class="{ outOfRange: isOutOfRange(n - 1 + ingredientStartIndex, formulation) }"> {{ formulation[selectedAttribute[n - 1 + ingredientStartIndex]] }}% </label> -->
                    <label v-show="!editMode" v-bind:class="{ outOfRange: isOutOfRange(n - 1 + ingredientStartIndex, formulation) }"> {{ selectedAttribute[n - 1]? formulation[selectedAttribute[n - 1 + ingredientStartIndex]]+'%'  : '' }} </label>
                    <InputText
                        v-show="editMode && selectedAttribute[n-1]"
                        :class="{ outOfRange: isOutOfRange(n - 1 + ingredientStartIndex, formulation) }"
                        :value="formulation[selectedAttribute[n - 1 + ingredientStartIndex]]"
                        @change="updateFormulation($event, formulation, selectedAttribute[n - 1 + ingredientStartIndex])"
                    />
                </div>
                <div class="col-1 output total" v-bind:class="{ outOfRange: formulation['Total'] > 100 }">{{ formulation['Total'] }}%</div>
            </div>
            <div v-show="ishomelaunch()" class="col-12 footer-row">
                <span>
                </span>
                <span class="">
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        @click="
                            addNewFormulation();
                            editMode = true;
                            predicted = false;
                            showGraph=false;
                        "
                        class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2"
                    />
                    <Button
                        label="Edit"
                        icon="pi pi-pencil"
                        v-show="!editMode"
                        @click="
                            editMode = true;
                            predicted = false;
                            this.showGraph=false
                        "
                        class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2"
                    />
                    <Button label="Delete" icon="pi pi-trash" v-show="itemsSelected" @click="deleteSeleted" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" />
                    <Button label="Save" icon="pi pi-save" v-show="editMode" @click="saveFormulations" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" />
                </span>
            </div>
        </div>
        <div class="col-12"></div>
        <div class="grid card shadow-2" v-show="predicted">
            <div class="col-8 title header-row">
                <span> Predictions </span>
            </div>
            <div class="col-2 header-row" ><Button label="Graph" icon="pi pi-chart-bar" @click="propertiesGraph" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" /></div>
            <div class="col-1 header-row"><Button label="Filter" icon="pi pi-filter" @click="propertiesFilter = true" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" /></div>
            <div class="col-1 header-row">
                <span class="">
                    <XlsxWorkbook>
                        <XlsxSheet :collection="downloadFormulations" v-if="formulations.length > 0" :sheet-name="'formulations'" />
                        <XlsxDownload :filename="SharedState.state.modelName + '- formulations.xlsx'">
                            <Button icon="pi pi-download" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2"></Button>
                        </XlsxDownload>
                    </XlsxWorkbook>
                 </span>
            </div>

            <div class="col-1 heading"></div>
            <div ref="PreDict" class="col-10 heading top-row">
                <i class="pi pi-caret-left" v-show="propertyStartIndex > 0" @click="propertyStartIndex--"></i>
                Properties
                <i class="pi pi-caret-right" v-show="propertyStartIndex < filteredProperties.length - 10" @click="propertyStartIndex++"></i>
            </div>
            <div class="col-1 heading"></div>

            <div class="col-1 heading">Formula</div>

            <div class="col-1 subheading" v-for="n in 10" :key="n">
            <div v-if="getPropertyAlternateName(n) != ' - '">
                <div v-helptext.top="getModelDescription(n)">
                    <label>
                        <small> {{ getPropertyAlternateName(n) }} </small>
                    </label>
                </div>
            </div>
                <div v-else>
                    <label>
                        <small> {{ getPropertyAlternateName(n) }} </small>
                    </label>
                </div>
            <div v-if="getModelAccuracy(n)">
                <div v-if="modelOptimizationParameter === 'normalized_root_mean_squared_error'" v-helptext.top="modelOptimizationParameter">
                    <label>
                        <small>{{ getModelAccuracy(n) }}</small>
                    </label>
                </div>
                <div v-if="modelOptimizationParameter === 'spearman_correlation'" v-helptext.top="modelOptimizationParameter">
                    <label>
                        <small>{{ (getModelAccuracy(n)*100).toFixed(2) }}%</small>
                    </label>
                </div>
            </div>
                <div v-else>
                    <label>
                        <small>-</small>
                    </label>
                </div>
            </div>
            <div class="col-1 heading">Cost / {{ CostUnit }}</div>

            <div v-for="(formulation, index) in formulations" :key="formulation.Name" class="row col-12 grid">
                <div class="col-1 row-head">{{ formulation.Name }}</div>
                <div class="col-1 input" v-for="n in 10" :key="n" v-bind:class="{ oddRow: index % 2 == 0 }">
                    <label> {{ formulation[filteredProperties[n - 1 + propertyStartIndex]] }} </label>
                </div>
                <div class="col-1 cost">{{ formulation['Cost'] }}</div>
            </div>
        </div>

        <div class="col-12"></div>

        <div class="grid card" v-show="addMode">
            <div class="col-6 title header-row">
                <span> New Formulation </span>
                <InputText v-model="newFormulation.Name" />
            </div>
            <div class="col-6 header-row">
                <span class="">
                    <Button label="Save & Close" @click="SaveAndClose" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" />
                    <Button label="Save & Add" @click="SaveAndAdd" class="p-button-rounded p-button-raised p-button-secondary p-ml-2 p-mb-2" />
                </span>
            </div>

            <div class="p-field col-4" v-for="inputAttribute in modelInputAttributes" :key="inputAttribute">
                <label for="name">{{ inputAttribute.Name }}</label>
                <InputText v-model="newFormulation[inputAttribute.Name]" class="field-text" />
                <Slider v-model="newFormulation[inputAttribute.Name]" :min="inputAttribute.LowerLimit" :max="inputAttribute.UpperLimit" />
            </div>
        </div>
        <div class="grid card shadow-2"  v-if="showGraph">
            <section class="col-12" style="text-align: left;">
                <div ref="Graphshow" class="col-5 title header-row">
                <span> Pivot on Formulations </span>
            </div>
            <br>
            <div class="card col-6 pivots" v-for="(props,index) in filteredProperties" :key="index">
            <h4 v-if="multiAxisData[index]">{{multiAxisData[index]['labels'][0]}}</h4>
                <Chart type="bar" :data="multiAxisData[index]" :options="multiAxisOptions "/>
            </div>
            </section>
            
        </div>
    </div>
    <!-- Formulator ends here -->
</template>

<script>
import FormulatorService from '../tipureServices/FormulatorService';

import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue3-xlsx';

import modelinfo from '../components/modelinfo.vue';
import AdminService from '../tipureServices/AdminService';

export default {
    inject: ['SharedState'],
    components: { XlsxWorkbook, XlsxSheet, XlsxDownload, modelinfo },
    data() {
        return {
            SourceAccount:null,
            targetAccount:null,
            Checkexistsflag:[],
            MGraphdata:[],
            showTutorial: false,
            showModelInfo: false,
            modelName: '',
            modelDescription: '',
            selectedAttribute: [],
            selectedProperty: [],
            selected: 'Select',
            costDialog: false,
            addMode: false,
            editMode: false,
            modelOptimizationParameter: '',
            modelInputAttributes: null,
            modelOutputAttributes: null,
            formulations: [],
            formulations_tmp:[],
            newFormulation: {},
            ingredientStartIndex: 0,
            propertyStartIndex: 0,
            predicted: false,
            loading: true,
            filteredProperties: [],
            modelProperties: null,
            modelAttributes: null,
            propertiesFilter: false,
            filtered: [],
            diplayNames: [],
            formulationsForDownload: [],
            showGraph:false,
            formulationGraph:null,
            multiAxisOptions:null,
            multiAxisData:[],
            GraphData:null,
            MainGraph:false,
            DetailedDescription:null,
            account:null
        };
    },
    computed: {
        downloadFormulations() {
            if (this.modelInputAttributes != null && this.modelOutputAttributes != null) {
                return this.formulations.map(this.translateToDownloadFormat);
            } else {
                return [{ status: 'Not initialized' }];
            }
        },
        itemsSelected() {
            return this.formulations.filter((formulation) => formulation['selected'] == true).length > 0;
        },
        inputAttributes() {
            return this.modelInputAttributes.map(function (attribute) {
                return attribute.Name;
            });
        },

        outputAttributes() {
            return this.modelOutputAttributes.map(function (attribute) {
                return attribute.Name;
            });
        },
    },
    formulatorService: null,
    adminService: null,
    created() {
        this.formulatorService = new FormulatorService();
        this.adminService = new AdminService();
        this.showGraph=false
    },
    mounted() {
        this.showGraph=false
        if (this.$route.params.account){
            this.account = this.$route.params.account;
            this.adminService.getModelInfo(this.$route.params.module, this.$route.params.account).then((data) => {
                if(data){
                this.updateScreen(data);
                }
                else{
                this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: "The Data returned Null", life: 3000 });
                this.loading = false;
                }
            }).catch((err) =>{
                this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: err, life: 3000 });
                this.loading = false;
            });
        }
        // else if (this.$route.params.account && !this.$route.params.myportfolio){
        //     this.adminService.getModelInfo(this.$route.params.module, this.$route.params.account).then((data) => {
        //         if(data){
        //         this.updateScreen(data);
        //         }
        //         else{
        //         this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: "The Data returned Null, formulation file may not be existing", life: 3000 });
        //         this.loading = false;
        //         }
        //     }).catch((err) =>{
        //         this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: err, life: 3000 });
        //         this.loading = false;
        //     });
        // } 
        else {
            this.formulatorService.getModelInfo(this.$route.params.module).then((data) => {
                if(data){
                this.updateScreen(data);
                }
                else{
                this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: "The Data returned Null", life: 3000 });
                this.loading = false;
                }
            }).catch((err) =>{
                this.$toast.add({ severity: 'error', summary: 'Error Occured', detail: err, life: 3000 });
                this.loading = false;
            });
        }
        this.SharedState.setModel(this.$route.params.module);
    },

    methods: {
        ishomelaunch(){
            if(this.$route.params.myportfolio)
            {                   
                let userId=this.$auth.getLoggedInUserId().split("@")[1]
                if (userId.replace('.','') === 'chemourscom')
                {
                    return true
                }
                else
                {
                    return false
                }
            }
            return true
            // console.log(userId.replace('.',''));
            
        },
        Checkexists(index,name){
            if(name){
            let testtmp3=[];
            for(let formulation in this.formulations){
                testtmp3.push(this.formulations[formulation]["Name"])
            }
            testtmp3.splice(index, 1);
                if(testtmp3.includes(name)){
                    this.Checkexistsflag[index]=true
                    return true
            }
            else{
                this.Checkexistsflag[index]=false
                return false
            }
        }
},
        translateToDownloadFormat(formulation) {
            let downloadableFomulation = { Name: formulation.Name };

            this.modelInputAttributes.forEach((input) => {
                downloadableFomulation[input.AlternateName] = formulation[input.Name];
            });
            let tmp={}
            for (let attribute in this.modelOutputAttributes){
                tmp[this.modelOutputAttributes[attribute]["Name"]]=this.modelOutputAttributes[attribute]["AlternateName"]
            }
            this.filteredProperties.forEach((output) => {
                downloadableFomulation[tmp[output]] = formulation[output];
            });

            return downloadableFomulation;
        },

        UpdateSettings() {
            this.formulatorService.updateCostSettings(this.SharedState.state.modelName, this.modelInputAttributes, this.CostUnit).then((data) => {
                if (data.status_code == 201) {
                    this.costDialog = false;
                    this.$toast.add({ severity: 'success', summary: 'Update Complete', detail: 'Cost settings updated successfully', life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Update Complete', detail: 'Cost settings could not be updated. Try again later', life: 3000 });
                }
            });
        },
        getModelProperty(index) {
            if (index <= this.filteredProperties.length) {
                return this.filteredProperties[index - 1 + this.propertyStartIndex];
            } else return ' - ';
        },

        getModelAccuracy(index) {
            if (index <= this.filteredProperties.length) {
                let Maccu=String(this.modelProperties[this.filteredProperties[index - 1 + this.propertyStartIndex]]['Accuracy']);
                Maccu=Maccu.replace(/ /g,'')
                if((Maccu === "NA")||(Maccu === "")){
                    return null;
                }
                else{
                    return Maccu;
                }
            } else return null;
        },
        getModelDescription(index) {
            if (index <= this.filteredProperties.length) {
                var propName = this.filteredProperties[index - 1 + this.propertyStartIndex];
                var Description = this.modelProperties[propName]['Description'];
                var name = this.modelProperties[propName]['Name'];
                return Description == null || Description == '' ? name : Description;
            } else return '';
        },

        getPropertyAlternateName(index) {
            if (index <= this.filteredProperties.length) {
                var propName = this.filteredProperties[index - 1 + this.propertyStartIndex];
                if(this.modelProperties[propName]['AlternateName']){
                var alternateName = this.modelProperties[propName]['AlternateName'];
                var name = this.modelProperties[propName]['Name'];
                return alternateName == null || alternateName == '' ? name : alternateName;
            } else return propName;
        }else return ' - ';
        },
        getAtributeRange(index) {
            if (this.modelAttributes) {
                let attribute = this.modelAttributes[this.selectedAttribute[index]];
                if(attribute){
                return attribute.LowerLimit + '-' + attribute.UpperLimit; // this.modelAttributes['balance'].UpperLimit; //this.modelAttributes[this.filteredProperties[index - 1 + this.propertyStartIndex]]['Accuracy'];
            }
            else return ""
        }else return '';
        },
        getAtributeAlternateName(index) {
            if (this.modelAttributes) {
                let attribute = this.modelAttributes[this.selectedAttribute[index]];
                if(attribute){
                if (attribute.AlternateName && attribute.AlternateName != '') {
                    return attribute.AlternateName;
                } else {
                    return attribute.Name;
                }
            }
            else return '-'
            } else return '';
            
        },
        isOutOfRange(index, formulation) {
            if(formulation){
            if (this.modelAttributes) {
                let attribute = this.modelAttributes[this.selectedAttribute[index]];
                if(attribute){
                return parseInt(formulation[attribute.Name]) < attribute.LowerLimit || parseInt(formulation[attribute.Name]) > attribute.UpperLimit;
                }
                else return 0
            } else {
                return false;
            } 
        }
        },

        getIngredientProperty(index, property) {
            if (this.modelAttributes) {
                let attribute = this.modelAttributes[this.selectedAttribute[index]];
                if(attribute){
                return "'" + attribute[property] + "'"; // this.modelAttributes['balance'].UpperLimit; //this.modelAttributes[this.filteredProperties[index - 1 + this.propertyStartIndex]]['Accuracy'];
            } else return ''
        }
        else return '';
        },

        getPaintProperty(index, property) {
            if (this.modelProperties) {
                let attribute = this.modelProperties[this.filteredProperties[index]];
                if (attribute) return "'" + attribute[property] + "'";
                // this.modelAttributes['balance'].UpperLimit; //this.modelAttributes[this.filteredProperties[index - 1 + this.propertyStartIndex]]['Accuracy'];
                else return '';
            } else return '';
        },

        deleteSeleted() {
            if (!confirm(" Selected Formulations will deleted permanently. Press 'OK' to confirm.")) {
                return;
            }
            let i = 0;
            while (i < this.formulations.length) {
                if (this.formulations[i]['selected'] == true) {
                    this.formulations.splice(i, 1);
                } else {
                    i++;
                }
            }
            this.showGraph=false;
            this.predicted=false;
            this.saveFormulations();
        },
        updateScreen(data) {
            this.showGraph=false;
            this.Checkexistsflag=[];
        
            this.modelOptimizationParameter = data['OptimizationParameter'];

            this.modelInputAttributes = data['InputAttributes'];

            this.modelOutputAttributes = data['OutputAttributes'];

            this.selectedAttribute = data['selectedAttributes'];

            this.formulations = data['formulations'];

            this.formulations_tmp=this.formulations

            this.modelName = data['Model'];

            this.modelDescription = data['ModelDescription'];

            this.DetailedDescription =data['DetailedDescription'];

            this.CostUnit = data['CostUnit'];

            this.modelProperties = Object.assign({}, ...this.modelOutputAttributes.map((x) => ({ [x.Name]: x })));

            this.modelAttributes = Object.assign({}, ...this.modelInputAttributes.map((x) => ({ [x.Name]: x })));
            this.filteredProperties = data['selectedProperties'];

            this.filtererd = data['selectedProperties'];

            this.ComputeCost();
            this.loading = false;
        },
        updateFormulation(event, formulation, attr) {
            let val = isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value);
            formulation[attr] = val;
            event.target.value = val;
            this.ComputeCost();
        },

        SaveAndClose() {
            this.formulations.push(this.newFormulation);
            this.newFormulation = {};
            this.ComputeCost();
            this.addMode = false;
        },
        SaveAndAdd() {
            this.formulations.push(this.newFormulation);
            this.ComputeCost();
            this.newFormulation = {};
        },
        addNewFormulation() {
            // let template = Object.assign({}, this.formulations[0]);
            // let formulationName = this.formulations.length > 0 ? this.formulations[0].Name.split(' ')[0] + ' ' + (this.formulations.length + 1) : 'Paint 1';
            let formulationName=""
            let template = Object.assign({}, ...this.modelInputAttributes.map((attr) => (attr.Name == 'Name' ? { [attr.Name]: formulationName } : { [attr.Name]: 0.0 })));
            template['Name'] = formulationName;
            this.formulations.push(template);
        },
        ComputeCost() {
            this.formulations.forEach((formulation) => {
                var total = 0.0;
                var cost = 0.0;
                this.modelInputAttributes.forEach((attr) => {
                    var attrValue = isNaN(formulation[attr.Name]) ? 0 : formulation[attr.Name];
                    var attrCost = isNaN(attr.CostPerUnit) ? 0 : attr.CostPerUnit;
                    total += attrValue; // formulation[attr]; // isNaN(formulation[attr]) ? 0.0 : formulation[attr];
                    cost += attrValue * attrCost;
                });

                formulation.Total = parseFloat(total).toFixed(2);
                formulation.Cost = (parseFloat(cost) / 100).toFixed(2);
            });
        },
        resetSelected(formulation_item) {
            formulation_item['selected'] = '';
            return formulation_item;
        },
    DispMainGraphdata(form,Sprop){
    this.MGraphdata=[]
    let tmp_dic={}
    for(let formulaton in form){
        let tmp_dic2={}
        for(let property in Sprop){
        tmp_dic2[Sprop[property]]=form[formulaton][Sprop[property]]
            }
    tmp_dic[form[formulaton]["Name"]]=tmp_dic2
        }
    this.MGraphdata.push(tmp_dic)
    this.MGraphdata.push(Sprop)
    },
async propertiesGraph(){
  this.GraphData= await this.CreateGraph();
    this.multiAxisOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057',
                            boxWidth:15
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                        //     callback: function (value) {
                        //     return numeral(value).format('$ 0,0')
                        // },
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
    }
    

let filterddata=[]
if(this.filteredProperties && this.filtered.length>0){
    for(let sortd in this.filtered){
for(let Gdata in this.GraphData){
    if(this.filtered[sortd]===this.GraphData[Gdata]["labels"][0])
    {
        filterddata.push(this.GraphData[Gdata]);
        break;
    }
}
}
}
else if(this.filteredProperties){
for(let sortd in this.filteredProperties){
for(let Gdata in this.GraphData){
    if(this.filteredProperties[sortd]===this.GraphData[Gdata]["labels"][0])
    {
        filterddata.push(this.GraphData[Gdata]);
        break;
    }
}
}
}
let modelprop=this.modelProperties
for(let data in filterddata){
    if(modelprop[filterddata[data]["labels"][0]]["AlternateName"]){
        filterddata[data]["labels"][0]=modelprop[filterddata[data]["labels"][0]]["AlternateName"]
    }
}
this.multiAxisData=[];
for(let fdata in filterddata){
    this.multiAxisData.push(filterddata[fdata])
}

this.showGraph=true; 
this.$nextTick(() => {
    this.scrollToElement(this.$refs.Graphshow);
      });
},

CreateGraph(){
    let tmp_graph=this.MGraphdata
    return new Promise(function(resolve) {
        let backGroundColors=
        [
    "#F20808",
    "#E76908",
    "#A67ADC",
    "#D9E216",
    "#78D508 ",
    "#0D4D2C",
    "#A30960",
    "#5EC77B", 
    "#2D9B96",
    "#D417CE",
    "#1ABB06",
    "#0EDFBB",
    "#8DFE03",
    "#1E0FC5",
    "#0482C0",
    "#AA0EDA",
    "#421F22"
        ]
    let lumsumdata=[]
    let datasetstmp=[]
    let formulation_tmp=tmp_graph[0]
    let Filtered_prop=tmp_graph[1]
    let tmpcolor=0
    let backGroundColr={}
for(let jk in formulation_tmp){
   if(tmpcolor>(backGroundColors.length-1)){
    tmpcolor=0
   }
   else{
backGroundColr[jk]=backGroundColors[tmpcolor]
tmpcolor=tmpcolor+1
}
}
//Sort starts here
let tmp_list=[]
for(let i in formulation_tmp){
    tmp_list.push(i)
}
for(let j=0;j<=tmp_list.length;j++){
    for(let i=0;i<tmp_list.length-1;i++){
        let tmp1=tmp_list[i].replace(/ /g,'')
        tmp1=Number(tmp1.substr(5,tmp1.length))
        let tmp2=tmp_list[i+1].replace(/ /g,'')
        tmp2=Number(tmp2.substr(5,tmp2.length))
        if (tmp1>tmp2){
               let exg=tmp_list[i]
               tmp_list[i]=tmp_list[i+1]
               tmp_list[i+1]=exg
        }
    }}
    let tmp_dic={}
    for (let l in tmp_list){
        tmp_dic[tmp_list[l]]=formulation_tmp[tmp_list[l]]
    }
//Sort ends here
for(let i in Filtered_prop){
    datasetstmp=[]
for(let j in tmp_dic){
    datasetstmp.push({
        label:j,
        // backgroundColor:"rgba(255, 10, 13, 0.8)",
        backgroundColor:backGroundColr[j],
        data:[tmp_dic[j][Filtered_prop[i]]]
    })
}

lumsumdata.push({
    labels:[Filtered_prop[i]],
    datasets:datasetstmp
})
}
console.log(lumsumdata);
resolve(lumsumdata);
  });
    
},

testrange(){
let confirmit=false;
let form=this.formulations;
let mod=this.modelAttributes;
return new Promise(function(resolve) {
for(let formulatn in form){
    if(confirmit){
        break
    }
for(let i in mod){
if((form[formulatn][i] < parseInt(mod[i].LowerLimit)) || (form[formulatn][i]  > parseInt(mod[i].UpperLimit)))
{
    console.log(i,form[formulatn][i]);
    console.log("Not in Upper and Lower limit");
    confirmit= true
    break
}
}
}
if(!confirmit){
for(let formulatn in form){
let sum=0;
for(let j in mod){
// console.log(form[formulatn][j]);
sum=sum+parseFloat(form[formulatn][j])
}
sum=sum.toFixed(2)
if(sum != 100)
{
    // console.log("sum is not 100, its just "+sum);
    confirmit= true
}
}
}
resolve(confirmit)
  });
},

saveFormulations() {
    let cflag=this.Checkexistsflag
    for(let y in this.formulations)
    {
        if(this.formulations[y]["Name"].length<1)
        {
            alert("Formulas cannot be Empty")
            return null
        } 
    }
    // let oflag=await this.testrange()
    for(let val in cflag){
        if((cflag[val]===true))
        {
        alert("The Formulas should be unique, two or more formulas should not have the same name")
        return null
        }
    }
    // console.log(oflag);
    // if(oflag){
    //     this.loading = false;
    //     alert("The ingridients should be in the provided range and should not exceed 100.00% in Total")
    //     return null
    // }
            this.loading = true;
            let formulations_dictionary = Object.assign(
                {},
                ...this.formulations.map((x) => ({
                    [x.Name]: this.resetSelected(x),
                }))
            );
            console.log(Object.values(formulations_dictionary));
            if(this.$route.params.account){
            this.formulatorService.updateModelFormulations_account(this.SharedState.state.modelName, this.$route.params.account,formulations_dictionary).then((data) => {
                //models
                if (data.status_code == 201) {
                    this.showGraph=false;
                    this.editMode = false;
                    this.loading = false;
                    this.$toast.add({ severity: 'success', summary: 'Update Complete', life: 3000 });
                }
                else if(data.status_code == 501){
                    this.showGraph=false;
                    this.editMode = false;
                    this.loading = false;
                    this.$toast.add({ severity: 'error', summary: 'Update Failed',life: 3000 });
                }
            });
        }
        else{
            this.formulatorService.updateModelFormulations(this.SharedState.state.modelName,formulations_dictionary).then((data) => {
                //user Settings
                if (data) {
                    this.showGraph=false;
                    this.editMode = false;
                    this.loading = false;
                    this.$toast.add({ severity: 'success', summary: 'Update Complete', life: 3000 });
                }
            });
        }
        
        },
scrollToElement(REF) {
    const el=REF
    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  },
     predictProperties() {
            this.loading = true;
            let formulations_dictionary = Object.assign(
                {},
                ...this.formulations.map((x) => ({
                    [x.Name]: x,
                }))
            );

            let incompleteFormulations = this.formulations.filter(function (formulation) {
                return formulation.Total != 100;
            });

            if (incompleteFormulations.length != 0) {
                alert('Please ensure all formulation ingredients add up to 100');
                this.loading = false;
            } else {
               this.formulatorService.submitFormulationParameters(this.$route.params.module, formulations_dictionary,this.$route.params.account).then((data) => {
                    
                    let formulation_tmp=Object.values(data[0]);
                    for(let j=0;j<=formulation_tmp.length;j++){
                    for(let i=0;i<formulation_tmp.length-1;i++){
                        let tmp1=(formulation_tmp[i].Name).replace(" ","")
                        tmp1=Number(tmp1.substr(5,tmp1.length))
                        let tmp2=(formulation_tmp[i+1].Name).replace(" ","")
                        tmp2=Number(tmp2.substr(5,tmp2.length))
                        if (tmp1>tmp2){
                               let tmp=formulation_tmp[i]
                                formulation_tmp[i]=formulation_tmp[i+1]
                                formulation_tmp[i+1]=tmp
                        }
                    }
                    }
                    //Sorting ends here
                    this.formulations=formulation_tmp
                    this.formulationGraph=data[1]
                    this.DispMainGraphdata(this.formulations,this.filteredProperties)
                    this.editMode = false;
                    this.predicted = true;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.scrollToElement(this.$refs.PreDict);
                        });
                    this.$toast.add({ severity: 'success', summary: 'Predictions Completed', detail: 'Predictions are loaded and available for review below.', life: 1500 });
                });            
            }
        },
    },
};
</script>

<style lang="scss">
body {
    font-family: sans-serif;
}
.pivots{
    float: left;
}
.hidden {
    visibility: hidden;
    width: 0px;
    height: 0px;
}
.ChartHeader{
float: left;
margin:1em;
padding:1px;
font-weight: 600;
}
.cost-grid {
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        padding-left: 0.8rem;
        margin-bottom: 0.5rem;
    }
}

.formulator {
    padding: 0.3rem;
    color: #7c7c7c;
    p-button-rounded {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
    }

    .total,
    .cost {
        background-color: #5c5c5c !important;
    }
    .title,
    .header-row,
    .middle-align {
        display: inline-block;
        span {
            vertical-align: -webkit-baseline-middle;
        }
    }
    .middle-align {
        text-align: start;
    }

    .p-multiselect {
        width: 100%;
    }
    .grid {
        margin: 0px;
    }

    .card {
        border-collapse: collapse;
        border-color: #afafaf;
        border-style: solid;
        border-width: 1px;
        padding: 0rem;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        padding: 0.8rem;
        text-align: center;
        vertical-align: middle;
        font-size: 98%;
    }

    .top-row {
        padding: 0.5rem 0.1rem 0.5rem 0rem;
    }
    .warn {
        color: #f00;
    }
    .heading {
        text-align: center;
        vertical-align: middle;
        background: #0093bc;
        color: #fff;
        font-weight: 800;
    }
    .oddRow {
        background-color: #fff !important;
    }
    .row,
    .selected {
        padding: 0rem;
        border-bottom: 1px solid gray;
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12 {
            border-right: 1px solid gray;
            padding: 0.2rem 0.1rem;
            background-color: #dddddd;
            input {
                background-color: #fff;
                padding: 0rem 0rem;
                border-radius: 0px;
                width: 95%;
                border-color: #2c2cff;
                border-style: ridge;
            }
        }
        .total,
        .cost {
            background-color: dimgray !important;
        }
    }

    .selected {
        div {
            background-color: gray !important;
            color: #fff !important;
        }
    }

    select,
    input,
    button {
        background-color: #efefef;
        font-family: sans-serif;
        font-size: 90%;
    }
    select,
    label {
        display: block;
        width: 100%;
    }
    .colattr {
        width: 100%;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 1px;
        border-top: 1px aliceblue;
        border-style: solid;
    }

    .cost,
    .total,
    .section-heading {
        background-color: #5c5c5c !important;
        color: #cfcfcf !important;
        border-style: solid;
        border-top-width: 0px;
        border-left-width: 0px;
        border-bottom-width: 0px;
        border-collapse: collapse;
        padding: 0px;
    }
    .subheading {
        text-align: center;
        vertical-align: middle;
        background-color: dimgray;
        color: #efefef;
        border: #fff;
        border-width: 2px;
        font-weight: 400;
        padding: 0rem;

        label {
            display: block;
            width: 100%;
            border-color: #efefef;
            border-width: 0px 0px 1px 1px;
            border-style: solid;
            padding-top: 0.3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .output {
        background-color: cornflowerblue;
        color: #fefefe;
    }
    .title {
        font-weight: 600;
        font-size: 1.4rem;
        color: #6c6c6c;
        vertical-align: middle;
        text-align: right;
        button {
            font-weight: 100;
            float: none;
            font-size: 0.9rem;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
        }
    }
    .p-button {
        float: right;
        margin-left: 0.3rem;
    }

    .right-aligned {
        float: right;
    }
    .left-aligned {
        float: left;
    }

    .subheading-output {
        background-color: darkslateblue;
        color: #fff;
        font-weight: 400;
    }

    .field-text {
        float: right;
        border-width: 1px 1px 0px 1px;
        border-color: darkslateblue;
    }
    .input {
        background-color: dimgray;
        color: #6c6c6c;
    }
    .model {
        background-color: dimgray;
        font-weight: 100;
        padding: 0.5rem 0rem 0rem 0rem;
    }
    .pi-caret-right {
        float: right;
        font-size: 1.5rem;
    }

    .pi-caret-left {
        float: left;
        font-size: 1.5rem;
    }
    .clickable {
        label {
            cursor: pointer;
        }
    }

    .row-head {
        background-color: #f5f5f5;
        color: #3c3c3c;
        padding: 0.5px 0px;
        cursor: pointer;
    }
    .header-row {
        padding: 0.3rem;
        text-align: left;
    }
    .footer-row {
        padding: 0.3rem 0.3rem 0.5rem 0rem;
    }
    .outOfRange {
        color: #f55 !important;
    }
    .formulationError {
        border: 1.5px solid #f00;
    }
    .total,
    .cost {
        background-color: #5c5c5c !important;
    }
}
.graphButton{
    float:right;
    background: none;
    border: none;
    color: #f00;
    font-size: medium;
    }
    .graphButton:hover{
        background-color: rgb(212, 53, 53);
        color: #fff;
        padding: 2px;
    }
</style>


